
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";

export default defineComponent({
  components: {},
  name: "",
  setup() {
    // console.log('wwww', wx);

    const data = reactive({});
    onBeforeMount(() => {
      // 2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      // let m = wx.miniProgram
      // 3.组件挂载到页面之后执行-------onMounted
      // h5.miniProgram.navigateTo({url: '/pages/logs/logs'})
    });

    return {
      ...toRefs(data),
    };
  },
});
